"use strict";

var app = {
  mobileMenu: function mobileMenu() {
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
        }
      });
    }
  },
  megaMenu: function megaMenu() {
    var parent = $("header nav a.hasChildren");

    parent.click(function (e) {
      e.preventDefault();
      var topLevel = $(this).parent("li");
      var subMenu = $(topLevel).find(".subMenu");
      if (subMenu.hasClass("hidden")) {
        $(".subMenu").addClass("hidden");
        subMenu.removeClass("hidden");
      } else {
        subMenu.addClass("hidden");
      }

      //subMenu.removeClass("hidden");
    });
  },
  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  initTabs: function initTabs() {
    var tabs = $('#tabs-btns span');
    var tabContent = $('#tabs-content .tab-wrap-content-inner');

    $.each(tabContent, function (key, row) {
      var row = $(row);
      row.attr('data-tab-content', key);
      row.addClass('hidden');
    });

    $.each(tabs, function (key, row) {
      var row = $(row);
      row.attr('data-tab-btn', key);
      row.click(function () {
        var selected = $(this);
        tabs.removeClass('selected');
        selected.addClass('selected');
        tabContent.addClass('hidden');
        $('#tabs-content .tab-wrap-content-inner[data-tab-content=' + key + ']').removeClass('hidden').fadeIn();
      });

      if (key == 0) {
        row.trigger('click');
      }
    });
  },
  init: function init() {
    app.mobileMenu();
    app.megaMenu();

    if ($('#tabs').length > 0) {
      app.initTabs();
    }

    $("#logo-feed").slick({
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 1000,
      speed: 300,
      slidesToShow: 9,
      slidesToScroll: 1,
      nextArrow: "#nxt-btn",
      prevArrow: "#prev-btn",
      cssEase: "linear",
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 700,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }]
    });

    $("#case-studies").slick({
      dots: false,
      infinite: true,
      autoplay: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: "#nxt-btn2",
      prevArrow: "#prev-btn2",
      cssEase: "linear",
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });

    $("#js-home-slider").slick({
      appendDots: $('#paging'),
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: $('#nxt-btn'),
      prevArrow: $('#prev-btn'),
      fade: true,
      cssEase: "linear",
      autoplay: true,
      autoplaySpeed: 5000
    });
  }
};

$(document).ready(function () {
  var $tabs = $('#horizontalTab');
  $tabs.responsiveTabs({
    rotate: false,
    startCollapsed: 'accordion',
    collapsible: 'accordion',
    setHash: true,
    click: function click(e, tab) {
      $('.info').html('Tab <strong>' + tab.id + '</strong> clicked!');
    },
    activate: function activate(e, tab) {
      $('.info').html('Tab <strong>' + tab.id + '</strong> activated!');
    },
    activateState: function activateState(e, state) {
      console.log(state);
      $('.info').html('Switched from <strong>' + state.oldState + '</strong> state to <strong>' + state.newState + '</strong> state!');
    }
  });
});

$(document).ready(function () {
  app.init();
});